import React, { useState, useEffect, useCallback, useRef } from "react";
import StrokeTool from "./actionBar/StrokeTool";
import PageMask from "./actionBar/PageMask";
import FreeTextTool from "./actionBar/FreeTextTool";
import CommentTool from "./actionBar/CommentTool";
import SelectionMaskTool from "./actionBar/SelectionMaskTool";
const DrawingLayer = ({
  activeTool,
  pageNumber,
  annotations,
  onUpdateAnnotations,
}) => {
  const [penStrokes, setPenStrokes] = useState([]);
  const [markerStrokes, setMarkerStrokes] = useState([]);
  const [selectedPath, setSelectedPath] = useState(null);
  const [curtainHeights, setCurtainHeights] = useState({
    Top: 0,
    Bottom: 0,
  });
  const [textAnnotations, setTextAnnotations] = useState([]);
  const [commentAnnotations, setCommentAnnotations] = useState([]);
  const [selectionMasks, setSelectionMasks] = useState([]);
  const containerRef = useRef(null);
  // Marker tool configurations
  const marker = {
    options: {
      size: 20,
      thinning: 0,
      smoothing: 0,
      streamline: 0,
      easing: (t) => t,
      start: {
        taper: 0,
        easing: (t) => t,
        cap: true,
      },
      end: {
        taper: 0,
        easing: (t) => t,
        cap: true,
      },
    },
    color: "rgba(255, 255, 0, 0.3)",
  };

  // Pen tool configurations
  const pen = {
    options: {
      size: 10,
      thinning: 0,
      smoothing: 0.5,
      streamline: 0.5,
      easing: (t) => t,
      start: {
        taper: 50,
        easing: (t) => t,
        cap: true,
      },
      end: {
        taper: 50,
        easing: (t) => t,
        cap: true,
      },
    },
    color: "red",
  };

  useEffect(() => {
    const annotationsArray = Array.isArray(annotations) ? annotations : [];
    const currentPageAnnotation = annotationsArray.find(
      (anno) => anno?.page_index === pageNumber
    );

    if (currentPageAnnotation) {
      setPenStrokes(currentPageAnnotation.annotations?.pen?.strokes || []);
      setMarkerStrokes(
        currentPageAnnotation.annotations?.marker?.strokes || []
      );

      const textAnnos = currentPageAnnotation.annotations?.text || [];
      setTextAnnotations(
        textAnnos.map((textAnno) => ({
          text: textAnno.content || textAnno.text || "", // Handle both content and text fields
          position: textAnno.position || { x: 0, y: 0 },
          color: textAnno.color || "#000000",
          id: textAnno.id?.toString() || Date.now().toString(),
        }))
      );
      const commentAnnos = currentPageAnnotation.annotations?.comment || [];
      setCommentAnnotations(
        commentAnnos.map((commentAnnos) => ({
          text: commentAnnos.content || commentAnnos.text || "", // Handle both content and text fields
          position: commentAnnos.position || { x: 0, y: 0 },
          id: commentAnnos.id?.toString() || Date.now().toString(),
        }))
      );
      const selectionMaskAnnos =
        currentPageAnnotation?.annotations?.selectionMask || [];
      setSelectionMasks(
        selectionMaskAnnos.map((mask) => ({
          x: mask.x,
          y: mask.y,
          width: mask.width,
          height: mask.height,
          id: mask.id?.toString() || Date.now().toString(),
          color: mask.color,
        }))
      );

      // Ensure curtain heights are properly set
      setCurtainHeights({
        Top: currentPageAnnotation.annotations.pageMask?.height.Top,
        Bottom: currentPageAnnotation.annotations.pageMask?.height.Bottom,
      });
    } else {
      // Reset states if no annotation for this page
      setPenStrokes([]);
      setMarkerStrokes([]);
      setTextAnnotations([]);
      setCommentAnnotations([]);
      setSelectionMasks([]);

      if (activeTool === "page-mask") {
        setCurtainHeights({
          Top: 20,
          Bottom: 20,
        });
      } else {
        setCurtainHeights({
          Top: 0,
          Bottom: 0,
        });
      }
    }
  }, [pageNumber, annotations, activeTool]);

  const handleAnnotationChange = useCallback(
    (annoType, newAnno) => {
      const annotationsArray = Array.isArray(annotations) ? annotations : [];
      const currentPageAnnotation = annotationsArray.find(
        (anno) => anno?.page_index === pageNumber
      );

      let updatedAnnotations = annotationsArray;

      if (currentPageAnnotation) {
        updatedAnnotations = annotationsArray.map((anno) =>
          anno.page_index === pageNumber
            ? {
                page_index: pageNumber,
                annotations: {
                  ...anno.annotations,
                  pen: {
                    strokes:
                      activeTool === "pen" && annoType === "pen"
                        ? newAnno
                        : anno.annotations?.pen?.strokes || [],
                    color: pen.color,
                  },
                  marker: {
                    strokes:
                      activeTool === "marker" && annoType === "marker"
                        ? newAnno
                        : anno.annotations?.marker?.strokes || [],
                    color: marker.color,
                  },
                  pageMask: {
                    height:
                      annoType === "pageMask"
                        ? newAnno
                        : anno.annotations?.pageMask?.height || {
                            Top: curtainHeights.Top,
                            Bottom: curtainHeights.Bottom,
                          },
                  },
                  text:
                    annoType === "text"
                      ? newAnno.map((textAnno) => ({
                          content: textAnno.text,
                          position: textAnno.position,
                          color: textAnno.color || "#000000",
                          id: textAnno.id?.toString() || Date.now().toString(),
                        }))
                      : anno.annotations?.text || [],
                  comment:
                    annoType === "comment"
                      ? newAnno.map((commentAnno) => ({
                          content: commentAnno.text,
                          position: commentAnno.position,
                          id:
                            commentAnno.id?.toString() || Date.now().toString(),
                        }))
                      : anno.annotations?.comment || [],
                  selectionMask:
                    annoType === "selection-mask"
                      ? newAnno.map((mask) => ({
                          x: mask.x,
                          y: mask.y,
                          width: mask.width,
                          height: mask.height,
                          id: mask.id?.toString() || Date.now().toString(),
                          color: mask.color || "#8b6f24", // Ensure color is preserved
                        }))
                      : anno.annotations?.selectionMask || [],
                },
              }
            : anno
        );
      } else {
        // Fix: Properly handle creating new page annotation
        updatedAnnotations.push({
          page_index: pageNumber,
          annotations: {
            pen: {
              strokes:
                activeTool === "pen" && annoType === "pen" ? newAnno : [],
              color: pen.color,
            },
            marker: {
              strokes:
                activeTool === "marker" && annoType === "marker" ? newAnno : [],
              color: marker.color,
            },
            pageMask: {
              height:
                annoType === "pageMask"
                  ? newAnno
                  : {
                      Top: curtainHeights.Top,
                      Bottom: curtainHeights.Bottom,
                    },
            },

            text:
              annoType === "text"
                ? newAnno.map((textAnno) => ({
                    content: textAnno.text,
                    position: textAnno.position,
                    color: textAnno.color || "#000000",
                    id: textAnno.id?.toString() || Date.now().toString(),
                  }))
                : [],
            comment:
              annoType === "comment"
                ? newAnno.map((commentAnno) => ({
                    content: commentAnno.text,
                    position: commentAnno.position,
                    id: commentAnno.id?.toString() || Date.now().toString(),
                  }))
                : [],
            selectionMask:
              annoType === "selection-mask"
                ? newAnno.map((selectionMask) => ({
                    x: selectionMask.x,
                    y: selectionMask.y,
                    width: selectionMask.width,
                    height: selectionMask.height,
                    id: selectionMask.id?.toString() || Date.now().toString(),
                    color: selectionMask.color,
                  }))
                : [],
          },
        });
      }

      // Update local state based on annotation type
      if (annoType === "pen") {
        setPenStrokes(newAnno);
      } else if (annoType === "marker") {
        setMarkerStrokes(newAnno);
      } else if (annoType === "pageMask") {
        setCurtainHeights(newAnno);
      } else if (annoType === "text") {
        setTextAnnotations(newAnno);
      } else if (annoType === "comment") {
        setCommentAnnotations(newAnno);
      }

      onUpdateAnnotations(updatedAnnotations);
    },
    [
      annotations,
      pageNumber,
      activeTool,
      onUpdateAnnotations,
      pen.color,
      marker.color,
    ]
  );
  const updateCurtainHeight = useCallback(
    (newHeights) => {
      // Ensure minimum height of 20
      // const clampedHeights = {
      //   Top: Math.max(20, newHeights.Top),
      //   Bottom: Math.max(20, newHeights.Bottom),
      // };

      setCurtainHeights(newHeights);
      handleAnnotationChange("pageMask", newHeights);
    },
    [handleAnnotationChange]
  );

  const handlePathSelect = useCallback((path) => {
    setSelectedPath(path);
  }, []);

  const handlePathDeletion = useCallback(
    (strokeType, strokeIndex) => {
      const annotationsArray = Array.isArray(annotations) ? annotations : [];
      const currentPageAnnotation = annotationsArray.find(
        (anno) => anno?.page_index === pageNumber
      );

      if (!currentPageAnnotation) return;

      const updatedAnnotations = annotationsArray.map((anno) =>
        anno.page_index === pageNumber
          ? {
              page_index: pageNumber,
              annotations: {
                pen: {
                  strokes:
                    strokeType === "pen"
                      ? anno.annotations.pen.strokes.filter(
                          (_, i) => i !== strokeIndex
                        )
                      : anno.annotations.pen.strokes,
                  color: pen.color,
                },
                marker: {
                  strokes:
                    strokeType === "marker"
                      ? anno.annotations.marker.strokes.filter(
                          (_, i) => i !== strokeIndex
                        )
                      : anno.annotations.marker.strokes,
                  color: marker.color,
                },
                pageMask: {
                  height: anno.annotations?.pageMask?.height || {
                    Top: 0,
                    Bottom: 0,
                  },
                },
              },
            }
          : anno
      );

      if (strokeType === "pen") {
        setPenStrokes(
          currentPageAnnotation.annotations.pen.strokes.filter(
            (_, i) => i !== strokeIndex
          )
        );
      } else if (strokeType === "marker") {
        setMarkerStrokes(
          currentPageAnnotation.annotations.marker.strokes.filter(
            (_, i) => i !== strokeIndex
          )
        );
      }

      onUpdateAnnotations(updatedAnnotations);
    },
    [annotations, pageNumber, onUpdateAnnotations]
  );
  const handleTextDelete = useCallback(
    (index) => {
      const updatedAnnotations = textAnnotations.filter((_, i) => i !== index);
      handleAnnotationChange("text", updatedAnnotations);
    },
    [textAnnotations, handleAnnotationChange]
  );
  const handleCommentDelete = useCallback(
    (index) => {
      const updatedAnnotations = commentAnnotations.filter(
        (_, i) => i !== index
      );
      handleAnnotationChange("comment", updatedAnnotations);
    },
    [commentAnnotations, handleAnnotationChange]
  );

  return (
    <div
      ref={containerRef}
      className="drawing-layer"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "auto",
      }}
    >
      <div
        className="Canvas content"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <StrokeTool
          penOptions={pen.options}
          penColor={pen.color}
          markerOptions={marker.options}
          markerColor={marker.color}
          activeTool={activeTool}
          penStrokes={penStrokes}
          markerStrokes={markerStrokes}
          onStrokeChange={handleAnnotationChange}
          onPathSelect={handlePathSelect}
          onPathDelete={handlePathDeletion}
        />
        <PageMask
          curtainHeight={curtainHeights}
          activeTool={activeTool}
          containerRef={containerRef}
          updateCurtainHeight={updateCurtainHeight}
        />
        <FreeTextTool
          activeTool={activeTool}
          textAnnotations={textAnnotations}
          onTextChange={handleAnnotationChange}
          onTextEdit={handleAnnotationChange}
          onTextDelete={handleTextDelete}
        />
        <CommentTool
          activeTool={activeTool}
          commentAnnotations={commentAnnotations}
          onCommentChange={handleAnnotationChange}
          onCommentEdit={handleAnnotationChange}
          onCommentDelete={handleCommentDelete}
          containerRef={containerRef}
        />
        <SelectionMaskTool
          activeTool={activeTool}
          selectionMasks={selectionMasks}
          onSelectionChange={handleAnnotationChange}
          onSelectionEdit={handleAnnotationChange}
        />
      </div>
    </div>
  );
};

export default DrawingLayer;
