import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import {
  Highlighter,
  MessageSquareText,
  SquareDashedMousePointer,
  Pencil,
  TypeOutline,
  MousePointer2,
  ChevronLeft,
  ChevronRight,
  Blinds,
  Eraser,
} from "lucide-react";

import "./ActionBar.css";

function ActionBar({ setActiveTool, activeTool }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleActionBar = () => {
    setIsOpen(!isOpen);
  };

  const getButtonStyle = (toolName) => ({
    color: "White",
    backgroundColor: activeTool === toolName ? "#705610" : "transparent",
    "&:hover": {
      backgroundColor:
        activeTool === toolName ? "#664f1a" : "rgba(255, 255, 255, 0.08)",
    },
  });

  return (
    <div className={`action-bar-container ${isOpen ? "open" : "closed"}`}>
      <IconButton
        className="toggle-button"
        onClick={toggleActionBar}
        sx={{ color: "White", backgroundColor: "#8b6f24" }}
      >
        {isOpen ? (
          <ChevronRight strokeWidth={2.5} />
        ) : (
          <ChevronLeft strokeWidth={2.5} />
        )}
      </IconButton>

      <Stack
        className="ActionBar"
        direction="column"
        spacing={2}
        sx={{ alignItems: "center" }}
      >
        <IconButton
          title="select"
          size="large"
          sx={getButtonStyle("select")}
          onClick={() => setActiveTool("select")}
        >
          <MousePointer2 strokeWidth={2.5} />
        </IconButton>
        <IconButton
          title="Pen"
          aria-label="Pen tool"
          size="large"
          sx={getButtonStyle("pen")}
          onClick={() => setActiveTool("pen")}
        >
          <Pencil strokeWidth={2.5} />
        </IconButton>
        <IconButton
          title="Marker"
          size="large"
          sx={getButtonStyle("marker")}
          onClick={() => setActiveTool("marker")}
        >
          <Highlighter strokeWidth={2.5} />
        </IconButton>
        <IconButton
          title="Text"
          size="large"
          sx={getButtonStyle("text")}
          onClick={() => setActiveTool("text")}
        >
          <TypeOutline strokeWidth={2.5} />
        </IconButton>

        <IconButton
          title="Comment"
          size="large"
          sx={getButtonStyle("comment")}
          onClick={() => setActiveTool("comment")}
        >
          <MessageSquareText strokeWidth={2.5} />
        </IconButton>
        <IconButton
          title="Area Mask"
          size="large"
          sx={getButtonStyle("selection-mask")}
          onClick={() => setActiveTool("selection-mask")}
        >
          <SquareDashedMousePointer strokeWidth={2.5} />
        </IconButton>

        <IconButton
          title="Page Curtain"
          size="large"
          sx={getButtonStyle("page-mask")}
          onClick={() => setActiveTool("page-mask")}
        >
          <Blinds strokeWidth={2.5} />
        </IconButton>
        <IconButton
          title="Eraser"
          size="large"
          sx={getButtonStyle("eraser")}
          onClick={() => setActiveTool("eraser")}
        >
          <Eraser strokeWidth={2.5} />
        </IconButton>
      </Stack>
    </div>
  );
}

export default ActionBar;
