import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import './SearchComponent.css'; // Import the CSS file

const SearchComponent = ({
    onSearch,
    onNextResult,
    onPrevResult,
    totalMatches,
}) => {
    
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const handleSearch = () => {
        if (searchTerm.trim() !== "") {
            onSearch(searchTerm);
        }
        setIsOpen(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="search-bar">

            <Tooltip title="Search for">
                <IconButton onClick={() => setIsOpen(!isOpen)}>
                    <SearchIcon sx={{color: 'white'}}/>
                </IconButton>
            </Tooltip>

            {isOpen && (
                <div className="search-input-container">
                    <TextField
                        className="search-input"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        onKeyDown={handleKeyDown}
                    />
                    <Button
                        className="search-button"
                        onClick={handleSearch}
                        startIcon={<SearchIcon />}
                        variant="contained"
                        color="primary"
                    >
                    </Button>

                    <Tooltip title="Previous Result">
                        <Button onClick={onPrevResult} disabled={totalMatches <= 1}>
                            <KeyboardArrowUpIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Next Result">
                        <Button onClick={onNextResult} disabled={totalMatches <= 1}>
                            <KeyboardArrowDownIcon />
                        </Button>
                    </Tooltip>
                    
                    <IconButton className="close-button" onClick={() => setIsOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export default SearchComponent;