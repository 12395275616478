import React, { createContext, useEffect } from 'react';
import * as userServices from '../services/user.services';
export const UserCheckContext = createContext();

export function UserCheckProvider({ children }) {
    const userCheck = async () => {
        try {
            if (localStorage.getItem("logged") == true) {
              const userData = await userServices.getUserById(
                localStorage.getItem("id"),
                localStorage.getItem("email")
              );
              console.log(userData, "usrddadad");
              if (userData.data.isActive == false) {
                userServices
                  .Logout(localStorage.getItem("id"))
                  .then((res) => {
                    localStorage.removeItem("isAuthenticated");
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("id");
                    localStorage.removeItem("role");
                    localStorage.removeItem("name");
                    localStorage.removeItem("avatar");
                    localStorage.removeItem("accessedBooks");
                    localStorage.removeItem("clientIp");
                    window.location.href = "/";
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
        } catch (err) {
            console.error('User check failed', err);
        }
    };

    useEffect(() => {
        const handleUserAction = () => {
            userCheck();
        };
        document.addEventListener('click', handleUserAction);
        document.addEventListener('keydown', handleUserAction);

        return () => {
            document.removeEventListener('click', handleUserAction);
            document.removeEventListener('keydown', handleUserAction);
        };
    }, []);

    return (
        <UserCheckContext.Provider value={{}}>
            {children}
        </UserCheckContext.Provider>
    );
}
