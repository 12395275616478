import { useCallback, useRef } from "react";

export const useCurtainDrag = (
  containerRef,
  curtainHeights,
  setCurtainHeights,
  updateCurtainHeights
) => {
  const lastY = useRef(null);
  const animationFrameRef = useRef(null);

  const handleMouseMove = useCallback(
    (e, isDraggingTop) => {
      if (containerRef.current) {
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }

        animationFrameRef.current = requestAnimationFrame(() => {
          const containerRect = containerRef.current.getBoundingClientRect();
          const deltaY = e.clientY - (lastY.current || 0);

          if (isDraggingTop) {
            const newTopHeight = Math.max(
              20,
              Math.min(
                curtainHeights.Top + deltaY,
                containerRect.height - curtainHeights.Bottom
              )
            );
            updateCurtainHeights({
              Top: newTopHeight,
              Bottom: curtainHeights.Bottom,
            });
          } else {
            const newBottomHeight = Math.max(
              20,
              Math.min(
                curtainHeights.Bottom - deltaY,
                containerRect.height - curtainHeights.Top
              )
            );
            updateCurtainHeights({
              Top: curtainHeights.Top,
              Bottom: newBottomHeight,
            });
          }

          lastY.current = e.clientY;
        });
      }
    },
    [containerRef, curtainHeights, updateCurtainHeights]
  );

  const cleanupAnimation = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
  };

  return {
    lastY,
    handleMouseMove,
    cleanupAnimation,
  };
};
