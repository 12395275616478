import axios from "axios";
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from "../config/app.config";

// Base API URL
const API_URL = `${BASE_URL}version`;

// Get all versions
export const getAllVersions = async (userId) => {
  try {
    const response = await axios.get(
      `${API_URL}/get-all-versions/${CLIENT_REF}?userId=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching versions:", error);
  }
};

// Create a new version
export const createVersion = async (versionData) => {
  try {
    const response = await axios.post(
      `${API_URL}/insert-version/${CLIENT_REF}`,
      versionData,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating version:", error);
  }
};

// Delete a version by ID
export const deleteVersion = async (versionId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/delete-version-by-id/${CLIENT_REF}/${versionId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting version:", error);
  }
};

// Get version by ID
export const getVersionById = async (versionId) => {
  try {
    const response = await axios.get(
      `${API_URL}/get-version-by-id/${CLIENT_REF}/${versionId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching version by ID:", error);
  }
};

// Update an existing version
export const updateVersion = async (versionId, updatedData) => {
  try {
    const response = await axios.put(
      `${API_URL}/update-version-by-id/${CLIENT_REF}/${versionId}`,
      updatedData,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating version:", error);
  }
};
