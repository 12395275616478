import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Bowser from 'bowser';

import Login from './pages/login/login';
import Signup from './pages/signup/signup';
import Home from './pages/home/home';
import UserBooks from './pages/userBooks/userBooks';
import BookDetails from './pages/bookDetails/bookDetails';
import UserProfile from './pages/userProfile/userProfile';
import ContactUs from './pages/contactUs/contactUs';
import Catalouge from './pages/catalouge/catalouge';
import ResetPasswordRequest from './pages/resetPasswordRequest/resetPasswordRequest';
import ResetPassword from './pages/resetPassword/resetPassword';
import DigitalBook from './pages/digitalBook/digitalbook';
import InstallPWA from "../src/components/installPWA";

const MINIMUM_BROWSER_VERSIONS = {
  Chrome: 90,
  Firefox: 85,
  Safari: 14,
  Edge: 90,
  Opera: 70,
};

function App() {
  const [isBrowserSupported, setIsBrowserSupported] = useState(true);

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    const browserVersion = parseFloat(browser.getBrowserVersion());

    // Check if the browser version is below the minimum required
    if (
      MINIMUM_BROWSER_VERSIONS[browserName] &&
      browserVersion < MINIMUM_BROWSER_VERSIONS[browserName]
    ) {
      setIsBrowserSupported(false); // If browser is not supported, set state
    }
  }, []);

  if (!isBrowserSupported) {
    return (
      <div className="browser-lock-overlay">
        <h2 className="browser-lock-heading">Your browser is out of date!</h2>
        <p className="browser-lock-paragraph">
          Please update your browser to continue using the application.
        </p>
        <div className="browser-lock-links">
          <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer" className="browser-lock-link">
            Download Chrome
          </a>
          <br />
          <a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noopener noreferrer" className="browser-lock-link">
            Download Firefox
          </a>
          <br />
          <a href="https://www.microsoft.com/edge" target="_blank" rel="noopener noreferrer" className="browser-lock-link">
            Download Edge
          </a>
        </div>
      </div>
    );
  }
  

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact />
        {localStorage.getItem('isAuthenticated') ? <Route path="/login" component={Home} exact /> : <Route path="/login" component={Login} exact />}
        {localStorage.getItem('isAuthenticated') ? <Route path="/signup" component={Home} exact /> : <Route path="/signup" component={Signup} exact />}
        {localStorage.getItem('isAuthenticated') ? <Route path="/bookDetails/:bookId" component={BookDetails} exact /> : <Route path="/bookDetails/:bookId" component={Login} exact />}
        {localStorage.getItem('isAuthenticated') ? <Route path="/userBooks" component={UserBooks} exact /> : <Route path="/userBooks" component={Login} exact />}
        {localStorage.getItem('isAuthenticated') ? <Route path="/userProfile" component={UserProfile} exact /> : <Route path="/userProfile" component={Login} exact />}
        {localStorage.getItem('isAuthenticated') ? <Route path="/resetPasswordRequest" component={Home} exact /> : <Route path="/resetPasswordRequest" component={ResetPasswordRequest} exact />}
        {localStorage.getItem('isAuthenticated') ? <Route path="/resetPassword" component={Home} exact /> : <Route path="/resetPassword/:token" component={ResetPassword} exact />}
        <Route path="/contactUs" component={ContactUs} exact />
        <Route path="/catalouge" component={Catalouge} exact />
        <Route path="/digitalBook/:bookId" component={DigitalBook} exact />
      </Switch>
      <InstallPWA />
    </BrowserRouter>
  );
}

// Basic styles for the browser lock overlay
const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

export default App;
