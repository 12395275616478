import React, { useState, useCallback, useRef } from "react";
import { getStroke } from "perfect-freehand";

function getSvgPathFromStroke(stroke) {
  if (!stroke.length) return "";

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ["M", ...stroke[0], "Q"]
  );

  d.push("Z");
  return d.join(" ");
}

export default function StrokeTool({
  penOptions,
  penColor,
  markerOptions,
  markerColor,
  activeTool,
  penStrokes,
  markerStrokes,
  onStrokeChange,
  onPathSelect,
  onPathDelete,
}) {
  const [currentStroke, setCurrentStroke] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedPath, setSelectedPath] = useState(null);
  const svgRef = useRef(null);

  const getCoordinates = useCallback((e) => {
    if (!svgRef.current) return [0, 0];
    const svgRect = svgRef.current.getBoundingClientRect();
    return [e.clientX - svgRect.left, e.clientY - svgRect.top];
  }, []);

  const handlePointerDown = useCallback(
    (e) => {
      const path = e.target.closest("path");
      if (activeTool === "select") {
        if (path) {
          setSelectedPath(path);
          onPathSelect(path);
        } else {
          setSelectedPath(null);
          onPathSelect(null);
        }
      } else if (activeTool === "eraser") {
        if (path) {
          const strokeIndex = path.dataset.strokeIndex;
          const strokeType = path.dataset.strokeType;
          onPathDelete(strokeType, parseInt(strokeIndex, 10));
        }
      } else if (activeTool === "pen" || activeTool === "marker") {
        setIsDrawing(true);
        const [x, y] = getCoordinates(e);
        setCurrentStroke([[x, y, e.pressure]]);
      }
    },
    [getCoordinates, activeTool, onPathSelect, onPathDelete]
  );

  const handlePointerMove = useCallback(
    (e) => {
      if (!isDrawing || activeTool === "select" || activeTool === "eraser")
        return;
      const [x, y] = getCoordinates(e);
      setCurrentStroke((prev) => [...prev, [x, y, e.pressure]]);
    },
    [isDrawing, getCoordinates, activeTool]
  );

  const handlePointerUp = useCallback(() => {
    if (!isDrawing || activeTool === "select" || activeTool === "eraser")
      return;

    setIsDrawing(false);

    if (activeTool === "pen") {
      const updatedStrokes = [...penStrokes, currentStroke];
      onStrokeChange("pen", updatedStrokes);
    } else if (activeTool === "marker") {
      const updatedStrokes = [...markerStrokes, currentStroke];
      onStrokeChange("marker", updatedStrokes);
    }

    setCurrentStroke([]);
  }, [
    isDrawing,
    currentStroke,
    activeTool,
    penStrokes,
    markerStrokes,
    onStrokeChange,
  ]);

  return (
    <svg
      ref={svgRef}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerUp}
      style={{
        padding: "0px",
        margin: "0px",
        border: "0px",
        background: "transparent",
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        display: "block",
      }}
    >
      {penStrokes.map((stroke, index) => (
        <path
          key={`pen-${index}`}
          d={getSvgPathFromStroke(getStroke(stroke, penOptions))}
          fill={penColor}
          stroke={
            selectedPath &&
            selectedPath.getAttribute("d") ===
              getSvgPathFromStroke(getStroke(stroke, penOptions))
              ? "blue"
              : "none"
          }
          strokeWidth="2"
          data-stroke-index={index}
          data-stroke-type="pen"
        />
      ))}
      {markerStrokes.map((stroke, index) => (
        <path
          key={`marker-${index}`}
          d={getSvgPathFromStroke(getStroke(stroke, markerOptions))}
          fill={markerColor}
          stroke={
            selectedPath &&
            selectedPath.getAttribute("d") ===
              getSvgPathFromStroke(getStroke(stroke, markerOptions))
              ? "blue"
              : "none"
          }
          strokeWidth="2"
          data-stroke-index={index}
          data-stroke-type="marker"
        />
      ))}
      {currentStroke.length > 0 && (
        <path
          d={getSvgPathFromStroke(
            getStroke(
              currentStroke,
              activeTool === "pen" ? penOptions : markerOptions
            )
          )}
          fill={activeTool === "pen" ? penColor : markerColor}
        />
      )}
    </svg>
  );
}
