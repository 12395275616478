import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  memo,
} from "react";
import { MessageSquare, X, Minimize2 } from "lucide-react";

// Memoize subcomponents to prevent unnecessary re-renders
const CommentIcon = memo(
  ({
    id,
    index,
    activeTool,
    expandedCommentId,
    onCommentDelete,
    onToggleExpand,
  }) => (
    <div
      className="comment-icon"
      style={{
        width: "32px",
        height: "32px",
        backgroundColor: "#b48f2e",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: activeTool === "eraser" ? "not-allowed" : "pointer",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        transition: "transform 0.2s ease",
        transform: expandedCommentId === id ? "scale(1.1)" : "scale(1)",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (activeTool === "eraser") {
          onCommentDelete(index);
        } else {
          onToggleExpand(id);
        }
      }}
    >
      <MessageSquare size={20} color="#000000" />
    </div>
  )
);

const ExpandedComment = memo(
  ({ annotation, index, onEdit, onDelete, onClose }) => {
    const commentRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState("auto");
    const [position, setPosition] = useState({ top: false, left: false });

    useEffect(() => {
      if (!commentRef.current) return;

      const updatePosition = () => {
        const commentRect = commentRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Check if comment would go off-screen
        setPosition({
          top: commentRect.bottom > windowHeight,
          left: commentRect.right > windowWidth,
        });

        // Calculate max height based on window size
        const topOffset = commentRect.top;
        const bottomSpace = windowHeight - topOffset;
        const maxAllowedHeight = Math.min(bottomSpace - 40, 400);
        setMaxHeight(`${maxAllowedHeight}px`);
      };

      updatePosition();
      window.addEventListener("resize", updatePosition);
      return () => window.removeEventListener("resize", updatePosition);
    }, []);

    const handleEdit = useCallback(() => {
      onEdit(index);
    }, [onEdit, index]);

    const handleDelete = useCallback(() => {
      onDelete(index);
    }, [onDelete, index]);

    return (
      <div
        ref={commentRef}
        className="expanded-comment"
        style={{
          position: "absolute",
          ...(position.left ? { right: "0px" } : { left: "40px" }),
          ...(position.top ? { bottom: "0px" } : { top: "0px" }),
          backgroundColor: "#b48f2e",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          width: "300px",
          maxHeight: maxHeight,
          display: "flex",
          flexDirection: "column",
          zIndex: 1000,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="comment-header"
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "1px 2px",
            borderBottom: "1px solid #e0e0e0",
            backgroundColor: "#8b6f24",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <button
            onClick={onClose}
            className="hover:bg-gray-200 p-1 rounded"
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Minimize2 size={16} className="text-gray-600" />
          </button>
          <div style={{ display: "flex", gap: "8px" }}>
            <button
              onClick={handleEdit}
              className="hover:bg-gray-200 p-1 rounded"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MessageSquare size={16} className="text-gray-600" />
            </button>
            <button
              onClick={handleDelete}
              className="hover:bg-gray-200 p-1 rounded"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <X size={16} className="text-gray-600" />
            </button>
          </div>
        </div>
        <div
          className="comment-content"
          style={{
            padding: "12px 16px",
            overflowY: "auto",
            overflowX: "hidden",
            wordBreak: "break-word",
            fontSize: "14px",
            lineHeight: "1.6",
            color: "#1f2937",
          }}
        >
          {annotation.text}
        </div>
      </div>
    );
  }
);

const EditingForm = memo(
  ({ editPosition, editingComment, onCommentChange, onSubmit, onCancel }) => (
    <div
      className="editing-form"
      style={{
        position: "absolute",
        left: editPosition.x,
        top: editPosition.y,
        zIndex: 1000,
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
        width: "300px",
        padding: "16px",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <textarea
        value={editingComment}
        onChange={(e) => onCommentChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onSubmit();
          } else if (e.key === "Escape") {
            onCancel();
          }
        }}
        autoFocus
        style={{
          width: "100%",
          minHeight: "120px",
          padding: "0px",
          border: "1px solid #e0e0e0",
          borderRadius: "6px",
          resize: "vertical",
          outline: "none",
          fontFamily: "inherit",
          fontSize: "14px",
          lineHeight: "1.5",
          marginBottom: "12px",
        }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
        <button
          onClick={onCancel}
          style={{
            padding: "8px 16px",
            border: "1px solid #e0e0e0",
            borderRadius: "6px",
            backgroundColor: "white",
            color: "#dc2626",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          Cancel
        </button>
        <button
          onClick={onSubmit}
          style={{
            padding: "8px 16px",
            border: "none",
            borderRadius: "6px",
            backgroundColor: "#2563eb",
            color: "white",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          Save
        </button>
      </div>
    </div>
  )
);

const CommentTool = React.memo(
  ({
    activeTool,
    commentAnnotations,
    onCommentChange,
    onCommentEdit,
    onCommentDelete,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editingComment, setEditingComment] = useState("");
    const [editPosition, setEditPosition] = useState({ x: 0, y: 0 });
    const [editingIndex, setEditingIndex] = useState(null);
    const [expandedCommentId, setExpandedCommentId] = useState(null);
    const canvasRef = useRef(null);

    // Memoize callback to prevent unnecessary re-renders
    const handleCanvasClick = useCallback(
      (e) => {
        if (activeTool !== "comment" || isEditing) return;
        if (!e.target.classList.contains("comment-tool")) return;

        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setEditPosition({ x, y });
        setIsEditing(true);
        setEditingComment("");
        setEditingIndex(null);
      },
      [activeTool, isEditing]
    );

    // Reset expanded comment when tool changes
    useEffect(() => {
      if (activeTool !== "comment") {
        setExpandedCommentId(null);
      }
    }, [activeTool]);

    // Memoized handlers to reduce unnecessary re-renders
    const handleCommentSubmit = useCallback(() => {
      if (!editingComment.trim()) return;

      const newCommentAnnotation = {
        id:
          editingIndex !== null
            ? commentAnnotations[editingIndex].id
            : Date.now().toString(),
        text: editingComment.trim(),
        position: editPosition,
        createdAt: new Date().toISOString(),
      };

      if (editingIndex !== null) {
        const updatedAnnotations = commentAnnotations.map((anno, index) =>
          index === editingIndex ? newCommentAnnotation : anno
        );
        onCommentEdit("comment", updatedAnnotations);
      } else {
        onCommentChange("comment", [
          ...commentAnnotations,
          newCommentAnnotation,
        ]);
      }

      setExpandedCommentId(newCommentAnnotation.id);
      setIsEditing(false);
      setEditingComment("");
      setEditingIndex(null);
    }, [
      editingComment,
      editPosition,
      editingIndex,
      commentAnnotations,
      onCommentChange,
      onCommentEdit,
    ]);

    const handleCommentChange = useCallback((newComment) => {
      setEditingComment(newComment);
    }, []);

    const handleToggleExpand = useCallback((id) => {
      setExpandedCommentId((prev) => (prev === id ? null : id));
    }, []);

    const handleEditComment = useCallback(
      (index) => {
        const currentAnnotation = commentAnnotations[index];
        setEditPosition(currentAnnotation.position);
        setEditingComment(currentAnnotation.text);
        setEditingIndex(index);
        setIsEditing(true);
        setExpandedCommentId(null);
      },
      [commentAnnotations]
    );

    const handleCancelEditing = useCallback(() => {
      setIsEditing(false);
      setEditingComment("");
      setEditingIndex(null);
    }, []);

    // Memoize comment annotations to prevent unnecessary re-renders
    const memoizedCommentAnnotations = useMemo(
      () =>
        commentAnnotations.map((annotation, index) => ({
          ...annotation,
          renderKey: `${annotation.id}-${
            expandedCommentId === annotation.id ? "expanded" : "icon"
          }`,
        })),
      [commentAnnotations, expandedCommentId]
    );

    return (
      <div
        ref={canvasRef}
        className="comment-tool"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: activeTool === "comment" ? "auto" : "none",
        }}
        onClick={handleCanvasClick}
      >
        {memoizedCommentAnnotations.map((annotation, index) => (
          <div
            key={annotation.renderKey}
            style={{
              position: "absolute",
              left: annotation.position.x,
              top: annotation.position.y,
              cursor: activeTool === "eraser" ? "pointer" : "default",
              pointerEvents: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {expandedCommentId === annotation.id ? (
              <ExpandedComment
                annotation={annotation}
                index={index}
                onEdit={() => handleEditComment(index)}
                onDelete={() => onCommentDelete(index)}
                onClose={() => setExpandedCommentId(null)}
              />
            ) : (
              <CommentIcon
                id={annotation.id}
                index={index}
                activeTool={activeTool}
                expandedCommentId={expandedCommentId}
                onCommentDelete={onCommentDelete}
                onToggleExpand={handleToggleExpand}
              />
            )}
          </div>
        ))}

        {isEditing && (
          <EditingForm
            editPosition={editPosition}
            editingComment={editingComment}
            onCommentChange={handleCommentChange}
            onSubmit={handleCommentSubmit}
            onCancel={handleCancelEditing}
          />
        )}
      </div>
    );
  }
);

export default CommentTool;
