import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DigitalSidebar from "../../components/digitalSidebar";
import Header from "../../components/header";
import PDFViewer from "../../components/PDFViewr";
import SideBar from "../../components/sideBar";
import VersionDropdown from "../../components/VersionDropdown";
import * as bookServices from "../../services/book.services";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const DigitalBook = () => {
  const bookId = useParams().bookId;
  const [book, setBook] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to manage sidebar visibility
  const [isOpen, setIsOpen] = useState(true); // Default to open
  useEffect(() => {
    setIsLoading(true);
    bookServices
      .getBookById(bookId)
      .then((response) => {
        setBook(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [bookId])

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsOpen(!isSidebarOpen);
  };

  return (
    <>
      <Header />

      <div
        className="toggle-button-sidebar"
        onClick={toggleSidebar}
        style={{
          position: "absolute",
          top: "23%",
          left: isOpen ? "30%" : "10%",
          backgroundColor: "#8b6f24",
          color: "white",
          borderRadius: "0%",
          width: "32px",
          height: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          zIndex: 10,
        }}
      >
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </div>

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <SideBar />
          {isSidebarOpen && (
            <Grid item xs={12} sm={3}>
              <DigitalSidebar book={book} setPageNumber={setPageNumber} />
            </Grid>
          )}
          <Grid item xs={12} sm={isSidebarOpen ? 8 : 11}>
            {isLoading ? (
              <Paper sx={{ p: 2 }}>Loading...</Paper>
            ) : (
              <PDFViewer
                pdfURL={book.digitalEdition}
                propPageNumber={pageNumber}
                mode="digital"
                bookId={bookId}
                sample={false}
              />
            )}
          </Grid>
        </Grid>
        <VersionDropdown
          bookId={bookId}
          pdfURL={book.digitalEdition}
          propPageNumber={pageNumber}
        />
      </Box>
    </>
  );
};

export default DigitalBook;
