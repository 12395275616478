import React, { useState, useCallback } from "react";
import { CheckCircle2 } from "lucide-react";

const DEFAULT_COLORS = [
  "#000000", // Black
  "#FF0000", // Red
  "#0000FF", // Blue
  "#008000", // Green
  "#FFA500", // Orange
  "#800080", // Purple
];

const FreeTextTool = ({
  activeTool,
  textAnnotations,
  onTextChange,
  onTextEdit,
  onTextDelete,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState("");
  const [editPosition, setEditPosition] = useState({ x: 0, y: 0 });
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#FF0000");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleCanvasClick = useCallback(
    (e) => {
      if (activeTool === "text" && !isEditing) {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setEditPosition({ x, y });
        setIsEditing(true);
        setEditingText("");
        setEditingIndex(null);
        setSelectedColor("#FF0000");
        setShowColorPicker(false);
        e.stopPropagation();
      }
    },
    [activeTool, isEditing]
  );

  const handleTextChange = useCallback(
    (newText) => {
      setEditingText(newText);

      if (editingIndex !== null) {
        const updatedAnnotations = textAnnotations.map((anno, index) =>
          index === editingIndex
            ? {
                ...anno,
                text: newText,
                color: selectedColor,
              }
            : anno
        );
        onTextEdit("text", updatedAnnotations);
      }
    },
    [editingIndex, textAnnotations, onTextEdit, selectedColor]
  );

  const handleTextSubmit = useCallback(() => {
    if (editingText.trim()) {
      const newTextAnnotation = {
        text: editingText,
        position: editPosition,
        id:
          editingIndex !== null
            ? textAnnotations[editingIndex].id
            : Date.now().toString(),
        color: selectedColor,
      };

      if (editingIndex !== null) {
        const updatedAnnotations = textAnnotations.map((anno, index) =>
          index === editingIndex ? newTextAnnotation : anno
        );
        onTextEdit("text", updatedAnnotations);
      } else {
        onTextChange("text", [...textAnnotations, newTextAnnotation]);
      }
    }
    setIsEditing(false);
    setEditingText("");
    setEditingIndex(null);
    setShowColorPicker(false);
  }, [
    editingText,
    editPosition,
    editingIndex,
    textAnnotations,
    onTextChange,
    onTextEdit,
    selectedColor,
  ]);

  const handleTextEdit = useCallback(
    (index) => {
      const annotation = textAnnotations[index];
      setEditPosition(annotation.position);
      setEditingText(annotation.text);
      setEditingIndex(index);
      setSelectedColor(annotation.color || "#000000");
      setIsEditing(true);
      setShowColorPicker(false);
    },
    [textAnnotations]
  );

  const handleColorChange = useCallback(
    (newColor) => {
      setSelectedColor(newColor);
      setShowColorPicker(false);

      if (editingIndex !== null) {
        const updatedAnnotations = textAnnotations.map((anno, index) =>
          index === editingIndex
            ? {
                ...anno,
                color: newColor,
              }
            : anno
        );
        onTextEdit("text", updatedAnnotations);
      }
    },
    [editingIndex, textAnnotations, onTextEdit]
  );

  const ColorPicker = () => (
    <div
      className="color-picker"
      style={{
        position: "absolute",
        top: "100%",
        left: 0,
        backgroundColor: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "8px",
        display: "flex",
        gap: "4px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        zIndex: 1001,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {DEFAULT_COLORS.map((color) => (
        <div
          key={color}
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: color,
            border:
              selectedColor === color ? "2px solid #666" : "1px solid #ccc",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleColorChange(color)}
        />
      ))}
      <input
        type="color"
        value={selectedColor}
        onChange={(e) => handleColorChange(e.target.value)}
        style={{
          width: "24px",
          height: "24px",
          padding: 0,
          border: "1px solid #ccc",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      />
    </div>
  );

  return (
    <div
      className="free-text-tool"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: activeTool === "text" ? "auto" : "none",
      }}
      onClick={handleCanvasClick}
    >
      {textAnnotations &&
        textAnnotations.map((anno, index) => (
          <div
            key={anno.id}
            style={{
              position: "absolute",
              left: anno.position.x,
              top: anno.position.y,
              cursor: activeTool === "select" ? "pointer" : "default",
              pointerEvents: "auto",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (activeTool === "select" || activeTool === "text") {
                handleTextEdit(index);
              } else if (activeTool === "eraser") {
                onTextDelete(index);
              }
            }}
          >
            <span
              style={{
                backgroundColor: "rgba(255, 255, 0, 0.8)",
                padding: "2px 4px",
                borderRadius: "2px",
                userSelect: "none",
                color: anno.color || "#000000",
              }}
            >
              {anno.text}
            </span>
          </div>
        ))}

      {isEditing && (
        <div
          style={{
            position: "absolute",
            left: editPosition.x,
            top: editPosition.y,
            zIndex: 1000,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ position: "relative", display: "flex", gap: "4px" }}>
            <input
              type="text"
              value={editingText}
              onChange={(e) => handleTextChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleTextSubmit();
                } else if (e.key === "Escape") {
                  setIsEditing(false);
                  setEditingText("");
                  setEditingIndex(null);
                  setShowColorPicker(false);
                }
              }}
              onBlur={(e) => {
                if (
                  !e.relatedTarget?.closest(".color-picker-container") &&
                  !e.relatedTarget?.closest(".color-picker") &&
                  !e.relatedTarget?.closest(".save-button")
                ) {
                  handleTextSubmit();
                }
              }}
              autoFocus
              style={{
                border: "1px solid #8b6f24",
                borderRadius: "4px",
                backgroundColor: "white",
                outline: "none",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                color: selectedColor,
              }}
            />
            <button
              className="color-picker-container"
              onClick={() => setShowColorPicker(!showColorPicker)}
              style={{
                width: "30px",
                height: "30px",
                padding: 0,
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: selectedColor,
                cursor: "pointer",
                outline: "none",
              }}
            />
            <button
              className="save-button"
              onClick={handleTextSubmit}
              title="save"
              style={{
                width: "30px",
                height: "30px",
                padding: "3px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#8b6f24",
                cursor: "pointer",
                outline: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckCircle2 size={20} color="#FFFFFF" />
            </button>
            {showColorPicker && <ColorPicker />}
          </div>
        </div>
      )}
    </div>
  );
};

export default FreeTextTool;
