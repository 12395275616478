import React from "react";
import { ChevronDown } from "lucide-react";

const Curtain = ({ isTop, height, isDragging, activeTool, onMouseDown }) => {
  const curtainClass = `page-mask-curtain ${
    isTop ? "top-curtain" : "bottom-curtain"
  } ${isDragging ? "dragging" : ""}`;

  return (
    <div
      className={curtainClass}
      style={{
        height: `${height}px`,
        cursor: activeTool === "page-mask" ? "ns-resize" : "default",
      }}
    >
      {activeTool === "page-mask" && (
        <div className="pull-cord" onMouseDown={(e) => onMouseDown(e, isTop)}>
          <ChevronDown size={16} color="white" className="icon" />
        </div>
      )}
    </div>
  );
};

export default Curtain;
