import React, { useState, useEffect } from "react";
import { useCurtainDrag } from "./useCurtainDrag";
import Curtain from "./Curtain";
import "./PageMask.css";

const PageMask = ({
  curtainHeight,
  activeTool,
  containerRef,
  updateCurtainHeight,
}) => {
  const [isDraggingTop, setIsDraggingTop] = useState(false);
  const [isDraggingBottom, setIsDraggingBottom] = useState(false);

  const { lastY, handleMouseMove, cleanupAnimation } = useCurtainDrag(
    containerRef,
    curtainHeight,
    updateCurtainHeight,
    updateCurtainHeight
  );

  const handleMouseDown = (e, isTop) => {
    if (e.target.closest(".pull-cord")) {
      if (isTop) {
        setIsDraggingTop(true);
      } else {
        setIsDraggingBottom(true);
      }
      lastY.current = e.clientY;
      e.preventDefault();
    }
  };

  const handleMouseUp = () => {
    if (isDraggingTop || isDraggingBottom) {
      setIsDraggingTop(false);
      setIsDraggingBottom(false);
      lastY.current = null;
      cleanupAnimation();
    }
  };

  useEffect(() => {
    return cleanupAnimation;
  }, []);

  useEffect(() => {
    if (isDraggingTop || isDraggingBottom) {
      const handleGlobalMouseMove = (e) => {
        handleMouseMove(e, isDraggingTop);
      };

      window.addEventListener("mousemove", handleGlobalMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      return () => {
        window.removeEventListener("mousemove", handleGlobalMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isDraggingTop, isDraggingBottom, handleMouseMove]);

  useEffect(() => {
    if (
      activeTool === "page-mask" &&
      (curtainHeight.Top < 20 || curtainHeight.Bottom < 20)
    ) {
      updateCurtainHeight({
        Top: Math.max(curtainHeight.Top, 20),
        Bottom: Math.max(curtainHeight.Bottom, 20),
      });
    }
  }, [activeTool, curtainHeight, updateCurtainHeight]);

  const shouldShowCurtains =
    curtainHeight.Top > 20 ||
    curtainHeight.Bottom > 20 ||
    activeTool === "page-mask";

  if (!shouldShowCurtains) {
    return null;
  }

  return (
    <>
      <Curtain
        isTop={true}
        height={curtainHeight.Top}
        isDragging={isDraggingTop}
        activeTool={activeTool}
        onMouseDown={handleMouseDown}
      />
      <Curtain
        isTop={false}
        height={curtainHeight.Bottom}
        isDragging={isDraggingBottom}
        activeTool={activeTool}
        onMouseDown={handleMouseDown}
      />
    </>
  );
};

export default PageMask;
