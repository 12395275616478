import React, { useState, useCallback, memo, useEffect } from "react";
import { X, Trash2, Eye, EyeOff, Palette } from "lucide-react";

const DEFAULT_COLORS = [
  "#8b6f24", // Default gold
  "#FF0000", // Red
  "#0000FF", // Blue
  "#008000", // Green
  "#FFA500", // Orange
  "#800080", // Purple
];

const ColorPicker = ({ selectedColor, onColorChange, onClose }) => (
  <div
    className="color-picker"
    style={{
      position: "absolute",
      top: "-36px",
      right: "64px",
      backgroundColor: "white",
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: "8px",
      display: "flex",
      gap: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
      zIndex: 1001,
    }}
    onClick={(e) => e.stopPropagation()}
  >
    {DEFAULT_COLORS.map((color) => (
      <div
        key={color}
        style={{
          width: "24px",
          height: "24px",
          backgroundColor: color,
          border: selectedColor === color ? "2px solid #666" : "1px solid #ccc",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={() => {
          onColorChange(color);
          onClose();
        }}
      />
    ))}
    <input
      type="color"
      value={selectedColor}
      onChange={(e) => {
        onColorChange(e.target.value);
        onClose();
      }}
      style={{
        width: "24px",
        height: "24px",
        padding: 0,
        border: "1px solid #ccc",
        borderRadius: "4px",
        cursor: "pointer",
      }}
    />
  </div>
);

const SelectionMask = memo(
  ({
    selection,
    onDelete,
    isSelected,
    isVisible,
    onToggleVisibility,
    onColorChange,
  }) => {
    const [showColorPicker, setShowColorPicker] = useState(false);

    return (
      <div
        style={{
          position: "absolute",
          left: selection.x,
          top: selection.y,
          width: selection.width,
          height: selection.height,
          backgroundColor: `${selection.color || "#8b6f24"}${
            isVisible ? "ff" : "4d"
          }`,
          border: `2px solid ${
            isSelected
              ? selection.color || "#8b6f24"
              : `${selection.color || "#8b6f24"}80`
          }`,
          cursor: "pointer",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {isSelected && (
          <div
            className="selection-controls"
            style={{
              position: "absolute",
              top: "-36px",
              right: "-8px",
              display: "flex",
              gap: "4px",
              backgroundColor: selection.color || "#8b6f24",
              padding: "4px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
            }}
          >
            {showColorPicker && (
              <ColorPicker
                selectedColor={selection.color || "#8b6f24"}
                onColorChange={onColorChange}
                onClose={() => setShowColorPicker(false)}
              />
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowColorPicker(!showColorPicker);
              }}
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: selection.color || "#8b6f24",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: 0,
              }}
              className="hover:bg-opacity-80 transition-colors"
            >
              <Palette size={16} color="white" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onToggleVisibility();
              }}
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: selection.color || "#8b6f24",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: 0,
              }}
              className="hover:bg-opacity-80 transition-colors"
            >
              {isVisible ? (
                <EyeOff size={16} color="white" />
              ) : (
                <Eye size={16} color="white" />
              )}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: "#dc2626",
                border: "none",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: 0,
              }}
              className="hover:bg-red-700 transition-colors"
            >
              <Trash2 size={16} color="white" />
            </button>
          </div>
        )}
      </div>
    );
  }
);

const SelectionMaskTool = ({
  activeTool,
  selectionMasks = [],
  onSelectionChange,
  onSelectionEdit,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentSelection, setCurrentSelection] = useState(null);
  const [selectedMaskId, setSelectedMaskId] = useState(null);
  const [visibilityState, setVisibilityState] = useState({});
  const [selectedColor, setSelectedColor] = useState("#8b6f24");

  const handleMouseDown = useCallback(
    (e) => {
      if (activeTool !== "selection-mask") return;
      if (!e.target.classList.contains("selection-mask-tool")) return;

      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      setIsDrawing(true);
      setStartPos({ x, y });
      setCurrentSelection({
        x,
        y,
        width: 0,
        height: 0,
      });
      setSelectedMaskId(null);
    },
    [activeTool]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDrawing) return;

      const rect = e.currentTarget.getBoundingClientRect();
      const currentX = e.clientX - rect.left;
      const currentY = e.clientY - rect.top;

      const width = currentX - startPos.x;
      const height = currentY - startPos.y;

      setCurrentSelection({
        x: width < 0 ? currentX : startPos.x,
        y: height < 0 ? currentY : startPos.y,
        width: Math.abs(width),
        height: Math.abs(height),
      });
    },
    [isDrawing, startPos]
  );

  const handleMouseUp = useCallback(() => {
    if (!isDrawing || !currentSelection) return;

    const newMask = {
      ...currentSelection,
      id: Date.now().toString(),
      color: selectedColor,
    };

    // Set default visibility for new mask
    setVisibilityState((prev) => ({
      ...prev,
      [newMask.id]: true,
    }));

    onSelectionChange("selection-mask", [...selectionMasks, newMask]);
    setIsDrawing(false);
    setCurrentSelection(null);
    setSelectedMaskId(newMask.id);
  }, [
    isDrawing,
    currentSelection,
    selectionMasks,
    onSelectionChange,
    selectedColor,
  ]);

  const handleMaskColorChange = useCallback(
    (maskId, newColor) => {
      // Ensure the color is updated for the specific mask
      const updatedMasks = selectionMasks.map((mask) =>
        mask.id === maskId ? { ...mask, color: newColor } : mask
      );

      // Update the selected color state
      setSelectedColor(newColor);

      // Trigger the selection edit with updated masks
      onSelectionEdit("selection-mask", updatedMasks);
    },
    [selectionMasks, onSelectionEdit]
  );
  const handleMaskClick = useCallback(
    (e, maskId) => {
      e.stopPropagation();
      if (activeTool === "selection-mask" || activeTool === "select") {
        setSelectedMaskId(selectedMaskId === maskId ? null : maskId);
        // Update selected color when selecting a mask
        if (selectedMaskId !== maskId) {
          const selectedMask = selectionMasks.find(
            (mask) => mask.id === maskId
          );
          if (selectedMask) {
            setSelectedColor(selectedMask.color || "#8b6f24");
          }
        }
      }
    },
    [activeTool, selectedMaskId, selectionMasks]
  );

  const handleMaskDelete = useCallback(
    (maskId) => {
      const updatedMasks = selectionMasks.filter((mask) => mask.id !== maskId);
      setVisibilityState((prev) => {
        const newState = { ...prev };
        delete newState[maskId];
        return newState;
      });
      onSelectionEdit("selection-mask", updatedMasks);
      setSelectedMaskId(null);
    },
    [selectionMasks, onSelectionEdit]
  );

  const handleToggleVisibility = useCallback((maskId) => {
    setVisibilityState((prev) => ({
      ...prev,
      [maskId]: !prev[maskId],
    }));
  }, []);

  useEffect(() => {
    const newVisibilityState = { ...visibilityState };
    let hasChanges = false;

    selectionMasks.forEach((mask) => {
      if (visibilityState[mask.id] === undefined) {
        newVisibilityState[mask.id] = true;
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setVisibilityState(newVisibilityState);
    }
  }, [selectionMasks]);

  useEffect(() => {
    if (activeTool !== "selection-mask" && activeTool !== "select") {
      setSelectedMaskId(null);
    }
  }, [activeTool]);

  return (
    <div
      className="selection-mask-tool"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: activeTool === "selection-mask" ? "auto" : "none",
        cursor: isDrawing ? "crosshair" : "default",
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {selectionMasks.map((mask) => (
        <div
          key={mask.id}
          onClick={(e) => handleMaskClick(e, mask.id)}
          style={{
            cursor: "pointer",
            pointerEvents:
              activeTool === "selection-mask" || activeTool === "select"
                ? "auto"
                : "none",
          }}
        >
          <SelectionMask
            selection={mask}
            isSelected={selectedMaskId === mask.id}
            onDelete={() => handleMaskDelete(mask.id)}
            isVisible={visibilityState[mask.id] ?? true}
            onToggleVisibility={() => handleToggleVisibility(mask.id)}
            onColorChange={(color) => handleMaskColorChange(mask.id, color)}
          />
        </div>
      ))}
      {currentSelection && activeTool === "selection-mask" && (
        <div
          style={{
            position: "absolute",
            left: currentSelection.x,
            top: currentSelection.y,
            width: currentSelection.width,
            height: currentSelection.height,
            backgroundColor: `${selectedColor}`,
            border: `2px dashed ${selectedColor}`,
          }}
        />
      )}
    </div>
  );
};

export default memo(SelectionMaskTool);
